import Meta from '@/components/Meta'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { Noto_Sans_KR } from 'next/font/google'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import * as gtag from '../lib/gtag'
import '../styles/globals.css'

const inter = Noto_Sans_KR({ weight: '400', subsets: ['latin'] })

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { t } = useTranslation('translation')

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <>
      <Meta title={t('site_name')} desc={t('description')} keyword={t('keywords')} />
      <DefaultSeo
        title={t('site_name')}
        description={t('description')}
        canonical={'https://www.walkmining.com'}
        twitter={{
          handle: '@Walkmining',
          site: '@Walkmining',
          cardType: 'summary_large_image',
          images: ['https://ad-banner.walkmining.com/web/preview.png'],
        }}
        languageAlternates={[
          {
            hrefLang: 'en-US',
            href: 'https://www.walkmining.com/',
          },
          {
            hrefLang: 'ko-KR',
            href: 'https://www.walkmining.com/ko/',
          },
          {
            hrefLang: 'ja-JP',
            href: 'https://www.walkmining.com/ja/',
          },
          {
            hrefLang: 'x-default',
            href: 'https://www.walkmining.com/',
          },
        ]}
        openGraph={{
          title: t('site_name'),
          siteName: t('site_name'),
          description: t('description'),
          article: {
            authors: [t('app_name')],
          },
          type: 'website',
          url: 'https://www.walkmining.com',
          images: [
            {
              url: 'https://ad-banner.walkmining.com/web/preview.png',
              alt: t('site_name'),
              width: 491,
              height: 243,
            },
          ],
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="nextjs-google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
        }}
      />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(MyApp)
